import React,{useContext} from "react";
import "../assets/scss/styles.scss"
import DarkFooter from "../components/Footers/DarkFooter.js";
import snake from '../assets/img/snake.svg'
import base from '../assets/img/base.svg'
import decision from '../assets/img/decision.svg'
import flex from '../assets/img/flex.svg'
import turbine from '../assets/img/turbine3.jpg'
import database from '../assets/img/database.jpg'
import business from '../assets/img/business.jpg'
import iot from '../assets/img/iot.jpg'
import { Context } from '../context/Context'
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';



// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../components/Headers/LandingPageHeader.js";



function ProjectPage() {
  const {project, isProjectLoading} = useContext(Context)
  const {title1, title2, section1pContent,section2pContent} = project
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        
 
        <section className="section section-about-us" id='summary'>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{color:'#3a1772',marginTop:'50px'}}>{title1}</h2>
                <p className="description" style={{textAlign:'justify',color:'#1d0840', fontSize:'1em'}}dangerouslySetInnerHTML={{__html: section1pContent }}/>                
              </Col>
            </Row>
            </Container>
            </section>
            <section id='objectives'>
        <div className="section section-team text-center" style={{backgroundColor:'#1d0840'}}>
        <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title" style={{color:'#ea9b84'}}>{title2}</h2>
             
              <p className="description" style={{textAlign:'center',color:'#1d0840', fontSize:'1em'}}dangerouslySetInnerHTML={{__html: section2pContent }}/>                
  
              </Col>
            </Row>
            </Container>
        </div>
        </section>
        <section id='workplan'>
        <div className="section section-team text-center" style={{backgroundColor:'#ededed'}}>
        <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title" style={{color:'#1d0840'}}>Work Plan
</h2>
              <Timeline lineColor={'#ddd'}>
  <TimelineItem
    key="001"
    dateText="Work Package 2"
    style={{ color: '#ea9b84' }}
  >
    <h4>Consumers incentivization for energy services</h4>
    <h5 style={{color: '#e04b26'}}>Leader UoA</h5>
    <br/>
    <p>
    The main objective of Work Package 2 is to analyse the existing regulatory
frameworks, market designs and business models in Europe related with ENFLATE
solution and propose new business models based on the identified digitalization
needs and technology enablers. Moreover, Work Package 2 will design the
flexibility services that will be demonstrated in the demonstrations’ campaigns
performing a thorough analysis of the environmental, economic and social benefits
offered by the proposed services and business models.
    </p>
  </TimelineItem>
  <TimelineItem
    key="002"
    dateText="Work Package 3"
    style={{color: '#ea9b84'}}
    dateInnerStyle={{ background: '#61b8ff', color: '#ededed' }}
    bodyContainerStyle={{
      background: '#ddd',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
    }}
  >
    <h4 style={{ }}>Interoperable IT platform for data-driven tools</h4>
    <h5 style={{ color: '#e04b26' }}>Leader UBE</h5>
    <br/>
    <p>
    Work Package 3 aims at describing the interactions and requirements for the
various components of the ENFLATE ICT platform and data flows, including the
interconnection with external, existing EU platforms. In addition, the software
services constituting the ENFLATE Platform Data Management Layer, including the
data management bus will be developed. Furthermore, the necessary measures for
the resolution of the several data handling, privacy and security issues will be
identified and implemented. Finally, this Work Package shall provide the energy
networks’ models for simulation studies in the various scenarios addressing the
multi-vector characteristics.
    </p>
  </TimelineItem>
  <TimelineItem
    key="004"
    dateText="Work Package 4"
    style={{ color: '#ea9b84' }}
  >
    <h4>Consumer-based market designs: Tools and demonstrations</h4>
    <h5 style={{color: '#e04b26'}}>Leader HSLU</h5>
    <br/>
    <p>
    The main objective of Work Package 4 is to develop and demonstrate ENFLATE’s
digital tools for consumer-centered flexibility markets. This Work Package will
prepare the pilots operations for the decentralised flexibility marketplaces of small-
scale DER and for the integrated marketplace flexibility. Demonstration activities
shall be carried in the Appenzell (Switcherland) and Skiathos (Greece) pilot testing
use cases. The results of the proposed demonstration will be evaluated in terms of
efficiency using the results of Work Package 2 as a benchmark.
    </p>
  </TimelineItem>
  <TimelineItem
    key="005"
    dateText="Work Package 5"
    style={{color: '#ea9b84'}}
    dateInnerStyle={{ background: '#61b8ff', color: '#ededed' }}
    bodyContainerStyle={{
      background: '#ddd',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
    }}
  >
    <h4 style={{ }}>Transmission System Operator - Distribution System Operator -
Consumer coordination for cross-vector flexibility services: Tools and
demonstrations</h4>
    <h5 style={{ color: '#e04b26' }}>Leader CUERVA</h5>
    <br/>
    <p>
    Work Package 5 focuses on the design and implementation of digital tools and
technologies for providing cross-vector flexibility services to Transmission System
Operators (TSOs) and Distribution System Operators (DSOs). The objective is to
identify how flexibility in district energy can be optimised to increase shares of
variable renewable energy supply and fluctuating demand or congestion. Strategies
for using the existing flexibility for grid stability and congestion management will be
studied. It will specify how the communication with the TSO and provided ancillary
services will be established. The management tool will be designed, programmed
and validated with simulation and/or historical data. The developed tools and
technologies will be demonstrated in Lachar (Spain) and in Kungsbacka (Sweden).
    </p>
  </TimelineItem>
  <TimelineItem
    key="006"
    dateText="Work Package 6"
    style={{ color: '#ea9b84' }}
  >
    <h4>Non-energy services for healthcare and mobility: Tools and
demonstrations</h4>
    <h5 style={{color: '#e04b26'}}>Leader CERTH</h5>
    <br/>
    <p>
    The main objective of Work Package 6 is to implement and assess healthcare and
mobility services in conjunction with energy requirements and collaboration with
local communities. This Work Package will establish the baseline to deploy AI-
powered healthcare and mobility solutions, provide an overview of the current
status in regard to actual infrastructure, tools, energy options and strategies which
lie in each pilot case, as well as it will design and prepare the pilots operations for
ENFLATE and its technologies / services in a pragmatic environment so as to set a
substantial validation phase in real-life environments. Finally, it will conduct an
overall evaluation of the system’s efficiency and added value, exhibiting
interoperability and seamless data sharing, as well as achievable energy savings and
CO 2 emissions reduction.
    </p>
  </TimelineItem>
  <TimelineItem
    key="005"
    dateText="Work Package 7"
    style={{color: '#ea9b84'}}
    dateInnerStyle={{ background: '#61b8ff', color: '#ededed' }}
    bodyContainerStyle={{
      background: '#ddd',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
    }}
  >
    <h4 style={{ }}>Communication, Dissemination, and Impact Assessment</h4>
    <h5 style={{ color: '#e04b26' }}>Leader ARTELYS</h5>
    <br/>
    <p>
    Work Package 7 runs in parallel with all the other Work Packages of ENFLATE from
the start of the project to its end and beyond. It manages and deploys ENFLATE’s
dissemination and communication activities, and materials. It promotes the
project’s results and outputs and overs two main areas of work. The first one is
dedicated to the communication around the ENFLATE solutions while the second
one deals with the assessment of the developed and tested solutions developed in
the project. A Scalability and Replicability Analysis (SRA) shall be performed on the
solutions developed in ENFLATE to allow the deployment of the proposed techno-
economic solutions while considering regulatory, technological, economic, social,
environmental, and market aspects. Life cycle assessment and impact studies will
be performed on ENFLATE’s solutions with the support of commercial and in-house
developed digital tools within the European Union’s energy transition framework.
    </p>
  </TimelineItem>
</Timeline>
              </Col>
            </Row>
            </Container>
        </div>
        </section>
        <DarkFooter />
        
      </div>
    </>
  );
}

export default ProjectPage;
