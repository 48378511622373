import React, { useState } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Graticule
} from "react-simple-maps";
import { PatternLines } from "@vx/pattern";
import france from '../assets/img/france.png'
import spain from '../assets/img/spain.png'
import swiss from '../assets/img/swiss.png'
import germany from '../assets/img/germany2.png'
import belgium from '../assets/img/belgium.png'
import greece from '../assets/img/greece.png'
import uk from '../assets/img/uk.png'
import bulgaria from '../assets/img/bulgaria.png'
import sweden from '../assets/img/sweden.png'
import swe from '../assets/img/sweden2.png'
import swis2 from '../assets/img/switzerland2.png'
import ubi from '../assets/img/logos/ubitech.svg'
import eifi from '../assets/img/logos/eifitech.svg'
import techinspire from '../assets/img/logos/techinspire.svg'
import fra from '../assets/img/france.svg'
import spa from '../assets/img/spain2.png'
import bulgar from '../assets/img/bulgar.svg'
import gre from '../assets/img/gre2.png'





const geoUrl = require ('./map.json')
const highlighted = [
  "IT",
  "IS",
  "IE",
  "FI",
  "NO",
  "UA",
  "PL",
  "CY",
  "BY",
  "LT",
  "LV",
  "EE",
  "PT",
  "DK",
  "NL",
  "LU",
  "AT",
  "CZ",
  "MD",
  "RO",
  "SI",
  "BA",
  "HR",
  "SK",
  "ME",
  "AL",
  "HU",
  "RS",
  "MK",
];
const high = [
  "GB"
];


const MapChart = () => {
  const [country, setCountry] = useState('Greece');
  const [countryimg, setCountryimg] = useState(greece);
  const [img1, setImg] = useState(gre);




  
  return (
    <div id='block_container'>
      <div id='bloc1'>
    <ComposableMap
      width={600}
      height={600}
      projection="geoAzimuthalEqualArea"
      projectionConfig={{
        rotate: [-10.0, -53.0, 0],
        scale: 800
      }}
    >
       <PatternLines
        id="lines"
        height={6}
        width={6}
        stroke="#dadada"
        strokeWidth={2}
        hover="disabled"
        background="#7456a5"
        orientation={["diagonal"]}
      />
      <Geographies geography={geoUrl} stroke="#3a1772" strokeWidth={0.5} >
      {({ geographies }) =>

              geographies.map((geo) => {
                const isHighlighted = highlighted.indexOf(geo.id) !== -1;
            return (
                <Geography
                  key={geo.rsmKey}
                  style={{
                    default: {
                     
                      outline: "none"
                    },
                    hover:{fill: "#ea9b84"},
                    
                    pressed: {
                      fill: "#E42",
                      outline: "none"
                    }
                    
                  }}
                  geography={geo}
                  fill={isHighlighted ? "url('#lines')" : "#dadada" }
                  
                 

                     
                  onMouseOver={() => {
                  
                   
                    if (!isHighlighted){
                      
                      const NAME = geo.properties.NAME;
                      setCountry(NAME)
                  
                      
                      if (NAME== 'France'){
                        setCountryimg(france)
                        setImg(fra)
                      }
                      else if (NAME== 'Spain'){
                        setCountryimg(spain)
                        setImg(spa)
                      }
                      else if (NAME== 'Switzerland'){
                        setCountryimg(swiss)
                        setImg(swis2)
                      }
                      else if (NAME== 'Germany'){
                        setCountryimg(germany)
                        setImg(eifi)
                      }
                      else if (NAME== 'Belgium'){
                        setCountryimg(belgium)
                        setImg(ubi)
                      }
                      else if (NAME== 'Greece'){
                        setCountryimg(greece)
                        setImg(gre)
                     
                      }
                      else if (NAME== 'United Kingdom'){
                        setCountryimg(uk)
                        setImg(techinspire)
                      }
                      else if (NAME== 'Bulgaria'){
                        setCountryimg(bulgaria)
                        setImg(bulgar)
                      }
                      else if (NAME== 'Sweden'){
                        setCountryimg(sweden)
                        setImg(swe)
                      }
                    

                    }
                    
                    
                  }}
                  onMouseLeave={() => {
                 
                  }}
                  
                />);
                })
        }
      </Geographies>
    </ComposableMap>
    </div>
   
    <div id='bloc2'>
    <p style={{color:'whitesmoke',fontSize:'17px'}}>Meet Our Partners</p>
    <div class="containerone">
    <div class="cardone">
      <div class="card__border">
       
        <img src={countryimg} alt="card image" class="card__img" />
      </div>

      <h3 class="card__name">{country}</h3>
      <hr style={{height:'2px'}}/>
      <div>
        <img src={img1}></img>
      </div>
      <span class="card__profession"></span>

    
    </div>
  </div>
</div>
   </div>
  );
};

export default MapChart;
