import React, {useContext} from 'react'
import "../assets/scss/styles.scss"

import CookieConsent, { Cookies } from "react-cookie-consent";
import ContactForm from '../components/contact'
import DarkFooter from "../components/Footers/DarkFooter.js";
import cons from './map.png'
import MapChart from '../components/MapChart';
import Mailchimp from '../components/Mailchimp'





// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../components/Headers/LandingPageHeader.js";
import { Context } from '../context/Context'
import video from '../assets/img/video.mp4'




function SinglePage() {
  const {about, isAboutLoading} = useContext(Context)
  const {titlesection1, titlesection2, section1Content, section2Content, section2Content2, subscribe} = about
  console.log(about)
  React.useEffect( () => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      
      <div className="wrapper" style={{position:'absolute', top:'50%', left:'50%', transform: "translate(-50%, -50%)"}}>
        
      <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    
      </div>
    </>
  );
}

export default SinglePage;
