import {marked} from 'marked'
import DOMPurify from 'dompurify'

export const getHTMLData = (rawdata) => {
    const htmlString = marked(rawdata)
    const santizedHTMLString = DOMPurify.sanitize(htmlString)
    return htmlString
}

export const CleanUpAbout = (rawdata) => {

    const{sys, fields} = rawdata
    const {id} = sys
    const titlesection1 = fields.section1title
    const section1Content = getHTMLData(fields.section1Content)
    const titlesection2 = fields.section2title
    const section2Content = getHTMLData(fields.section2OverMap)
    const section2Content2 = getHTMLData(fields.section2UnderMap)
    const facts = fields.enflateFacts
    const subscribe = fields.subscribe
    let cleanAbout = {id, titlesection1, titlesection2, section1Content, section2Content, section2Content2, facts, subscribe}

    return cleanAbout
}

export const CleanUpProject = (rawdata) => {

    const{sys, fields} = rawdata
    const {id} = sys
    const title1 = fields.title1
    const section1pContent = getHTMLData(fields.section1ProjectContent)
    const title2 = fields.title2
    const section2pContent = getHTMLData(fields.section2ProjectContent)



    let cleanProject = {id, title1, title2, section1pContent, section2pContent}

    return cleanProject
}

export const CleanUpPartners = (rawdata) => {

    const{sys, fields} = rawdata
    const {id} = sys
    const partner1 = fields.partner1
    const sectionpartner1 = getHTMLData(fields.description)
    const image1 = fields.image1.fields.file.url



    let cleanPartners = {id, partner1, sectionpartner1,image1}

    return cleanPartners
}