import React, {useState, useContext} from "react";
import "../assets/scss/styles.scss"
import { Context } from '../context/Context'


import DarkFooter from "../components/Footers/DarkFooter.js";

import eketa from '../assets/img/logos/certh.png'
import cuerva from '../assets/img/logos/cuerva.png'
import ubitech from '../assets/img/logos/ubitech.png'
import hslu from '../assets/img/logos/HSLU.svg'
import epexspot from '../assets/img/logos/epexspot.svg'
import artelys from '../assets/img/logos/artelys.svg'
import ivl from '../assets/img/logos/IVL.png'
import tech from '../assets/img/logos/Tech.svg'

import sak from '../assets/img/logos/sak.png'
import rae from '../assets/img/logos/rae-logo.png'
import stpg from '../assets/img/logos/stpg.png'
import skiathos from '../assets/img/logos/skiathos.svg'
import eifitech from '../assets/img/logos/eifitech2.svg'
import ekpa from '../assets/img/logos/ekpa.svg'
import lachar from '../assets/img/logos/lachar.png'
import nova from '../assets/img/logos/nova.png'
import hedno from '../assets/img/logos/hedno.svg'
import peiraias from '../assets/img/logos/peiraias.svg'
import ipto from '../assets/img/logos/ipto.png'
import setecho from '../assets/img/logos/setecho.svg'
import blocks from '../assets/img/logos/blocks.svg'
import edg from '../assets/img/logos/edg.svg'
import eso from '../assets/img/logos/eso.svg'
import ckw from '../assets/img/logos/ckw.png'
import cartif from '../assets/img/logos/cartif.svg'
import geneve from '../assets/img/logos/geneve.svg'
import eksta from '../assets/img/logos/eksta.svg'
import noda from '../assets/img/logos/noda.svg'
import abb from '../assets/img/logos/ABB.svg'
import turning from '../assets/img/logos/adaion.png'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import image1 from '../assets/img/pexels.jpg'






import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  ModalBody,
} from "reactstrap";

// core components
import ExamplesNavbar from "../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../components/Headers/LandingPageHeader.js";
import Modal from 'react-bootstrap/Modal';


function Partners() {
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [twiShow, setTwiShow] = useState(false);
  const [bendablShow, setBendablShow] = useState(false);
  const [vitensShow, setVitensShow] = useState(false);
  const [alsymexShow, setAlsymexShow] = useState(false);
  const [barbantShow, setBarbantShow] = useState(false);
  const [demconShow, setDemconShow] = useState(false);
  const [smsak, setSmsak] = useState(false);
  const [lgrae, setLgrae] = useState(false);
  const [stpgshow, setstpgShow] = useState(false);
  const [skiathosShow, setskiathosShow] = useState(false);
  const [eifitechShow, seteifitechShow] = useState(false);
  const [ekpaShow, setekpaShow] = useState(false);
  const [lacharShow, setlacharShow] = useState(false);
  const [novaShow, setnovaShow] = useState(false);
  const [iptoShow, setiptoShow] = useState(false);
  const [ckwshow, setckwShow] = useState(false);
  const [setechoShow, setsetechoShow] = useState(false);
  const [blocksShow, setblocksShow] = useState(false);
  const [edgShow, setedgShow] = useState(false);
  const [esoShow, setesoShow] = useState(false);
  const [geneveShow, setgeneveShow] = useState(false);

  const [nodaShow, setnodaShow] = useState(false);
  const [ekstaShow, setekstaShow] = useState(false);
  const [abbShow, setabbShow] = useState(false);
  const [hednoShow, sethednoShow] = useState(false);
  const [peiraiasShow, setpeiraiasShow] = useState(false);
  const [turningShow, setturningShow] = useState(false);
  const [cartifShow, setcartifShow] = useState(false);

  const {partners, isPartnersLoading} = useContext(Context)
  const {partner1, image1, sectionpartner1} = partners



  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <section className="section section-about-us" id='partners'>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{color:'#3a1772',marginTop:'50px'}}>Partners</h2>
                <section class="section section-default mt-none mb-none">
<div class="container">

<strong>
<div class="row">

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={ubitech} onClick={() => setDemconShow(true)}/>
<Modal 
        show={demconShow}
        onHide={() => setDemconShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title>
        <h5 className="title" style={{color:'#dadada'}}>UBITECH ENERGY
        <span> <a target="_blank" href="https://energy.ubitech.eu/" target="_blank" rel="noreferrer"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setDemconShow(false)}/>
        </Modal.Header>
        <Modal.Body><p>UBITECH ENERGY was founded by a blend of experienced ICT Consultants and Energy
Research Innovators, with a long track record in the identification, design, development and
commercialization of research and innovation smart energy projects. Building upon a deep
and thorough understanding of energy systems current needs and future challenges, these
projects have been addressing a wide range of technologies and domains – including smart
grid solutions, energy efficiency, IoT, cybersecurity and data privacy, renewables integration,
energy markets and services, blockchain technology, cloud computing and big data analytics.
The vision of UBITECH ENERGY is to revolutionize the energy value chain with new
technologies and pave the way for its new digital era.</p>
</Modal.Body>


      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={cuerva} onClick={() => setTwiShow(true)}/>
<Modal 
        show={twiShow}
        onHide={() => setTwiShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>Cuerva <span> <a target="_blank" href="https://cuervaenergia.com/" target="_blank" rel="noreferrer"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setTwiShow(false)}/>
        </Modal.Header>
        <Modal.Body><p>CUERVA has more than eighty years of operation in several
countries researching innovative energy-related solutions in
alignment with people’s needs. It uses advanced sensorization
approaches to monitor and export data with direct application in
the digital transformation processes of the grid (Smart Grids).
Using time synchronization as an engine, it improves electrical
substations and transformation centers that require the
replacement of analog and power technologies with
programmable digital devices and software tools aligned with the
Industry 4.0 trend.</p>
</Modal.Body>
      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={hslu} onClick={() => setAlsymexShow(true)}/>
<Modal 
        show={alsymexShow}
        onHide={() => setAlsymexShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>HSLU<span> <a target="_blank" href="https://www.hslu.ch/en/" target="_blank" rel="noreferrer"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setAlsymexShow(false)}/>
        </Modal.Header>
        <Modal.Body>Lucerne University of Applied Sciences and Arts (HSLU)
is the university of applied sciences of the six cantons of Central
Switzerland and unites the departments of Engineering &amp;
Architecture, Business, Information Technology, Social Work,
Design &amp; Art and Music. With over 8ʼ300 students in education
and 5,200 participants in CAS, DAS and MAS programmes,
almost 400 new research projects each year and over 1ʼ900
employees, it is the largest educational institution in the heart of
Switzerland.


</Modal.Body>
      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={artelys} onClick={() => setBendablShow(true)}/>
<Modal 
        show={bendablShow}
        onHide={() => setBendablShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>Artelys<span> <a target="_blank" href="https://www.artelys.com/" target="_blank" rel="noreferrer"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setBendablShow(false)}/>
        </Modal.Header>
        <Modal.Body>Artelys is an independent consulting and software edition
company specialised in energy systems modelling and decision-
support. Relying on high-level expertise in quantitative methods
and optimisation, Artelys supports clients all along the energy
value chain: utilities, regulators, TSOs, NGOs, energy ministries,
agencies, the IEA, the European Commission, etc. Artelys
combines a strong expertise of energy systems with cutting-edge
mathematical and optimization solutions integrated in its
software suite Artelys Crystal.


</Modal.Body>
      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={epexspot} onClick={() => setSmShow(true)}/>
<Modal 
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>Epex spot<span> <a target="_blank" href="https://www.epexspot.com/en" target="_blank" rel="noreferrer"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setSmShow(false)}/>
        </Modal.Header>
        <Modal.Body><strong>The European Power Exchange EPEX SPOT SE</strong> and its affiliates operate physical short-term electricity markets in 13 countries: in Central Western Europe, the United Kingdom, Switzerland, the Nordics and in Poland. Furthermore, EPEX SPOT newly offers local flexibility markets solution and Guarantees of Origin auctions, to foster the integration 
          of renewable energy sources and to enhance the engagement of consumers and producers in the power market.
        </Modal.Body>
        <Modal.Body>
          As part of EEX Group, a group of companies serving international commodity markets, EPEX SPOT is committed to the creation of a pan-European power market. Over 300 members trade electricity on EPEX SPOT. 49% of its equity is held by HGRT, a holding of transmission system operators. 
        </Modal.Body>
      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={eketa} onClick={() => setVitensShow(true)}/>
<Modal 
        show={vitensShow}
        onHide={() => setVitensShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>CERTH<span> <a target="_blank" href="https://www.certh.gr/" target="_blank" rel="noreferrer"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setVitensShow(false)}/>
        </Modal.Header>
        <Modal.Body>The ENFLATE project aims to increase the energy system
flexibility in various sectors, including distribution networks,
islands, transportation systems, etc. For this purpose, two
Institutes of the Centre for Research and Technology Hellas
(CERTH), i.e., the Chemical Process and Energy Resources
Institute (CPERI) and the Informatics and Telematics Institute
(ITI), cooperate in order to contribute their expertise and achieve
the project’s goals. More specifically, CERTH aims to develop
digital twins for all six of the project’s demonstrators and also
assess the environmental and socioeconomic impact of the
ENFLATE solution. Furthermore, CERTH aims to develop tools
and technologies especially for providing healthcare and
mobility services for two of the project’s demonstrators in
Bulgaria and in Switzerland. The main researchers to be
involved in this project are Dr. Dimitrios Rakopoulos, Ms. Maria
Fotopoulou, Mr. Andreas Seitaridis and Ms. Niki Skopetou from
the team of CERTH/CPERI, mostly related to the digital twins
and the environmental and socioeconomic impact assessment,
and Dr. Konstantinos Votis, Dr. Antonios Lalas, Dr.
Dimosthenis Ioannidis, and Dr. Yannis Moschos, from the team
of CERTH/ITI, mostly related to the healthcare and mobility
services.


</Modal.Body>
      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={ivl} onClick={() => setLgShow(true)}/>
<Modal centered show={lgShow}
        onHide={() => setLgShow(false)}>
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        
          <Modal.Title><h5 className="title" style={{color:'#dadada'}}>IVL<span> <a target="_blank" href="https://www.ivl.se/" target="_blank" rel="noreferrer"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setLgShow(false)}/>
        </Modal.Header>
        <Modal.Body>The Swedish Environmental Institute (IVL) is an independent
and independent research institute within the environment and
sustainability. We work with applied research and consulting
assignments that contribute to fulfilling both the global
sustainability goals and the Swedish environmental goals.

</Modal.Body>
      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={tech} onClick={() => setBarbantShow(true)}/>
<Modal 
        show={barbantShow}
        onHide={() => setBarbantShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>Tech Inspire<span> <a href="https://www.techinspire.co.uk/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setBarbantShow(false)}/>
        </Modal.Header>
        <Modal.Body>Tech Inspire is UK-based Small Medium Enterprise (SME)
established in 2013. Its vibrant team of highly skilled and
experienced engineers specialising in Data Security and Energy
solutions have over 20 years of combined experience through
cutting-edge successfully undertaken projects. As a global team
of experts from a variety of disciplines provide interdisciplinary
solutions that form the basis for several global security and
sustainability challenges. Tech Inspire covers a wide range of
market sectors, including Cyber Security, Energy &amp;
Environment, Health Care, Telecommunications and
Transportation, and leads the development of clear and
comprehensive technology roadmaps, policies and strategies.
</Modal.Body>
      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={ekpa} onClick={() => setekpaShow(true)}/>
<Modal 
        show={ekpaShow}
        onHide={() => setekpaShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>NKUA<span> <a href="https://uoa.gr/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setekpaShow(false)}/>
        </Modal.Header>
        <Modal.Body>The National and Kapodistrian University of Athens (UoA) is
the oldest University in Greece and the first Higher Education
Institution in the Balkan and Eastern Mediterranean area. The
Control Systems and Industrial Automation Laboratory of UoA
that participates in ENFLATE project covers the educational and
research activities in the areas of control systems, power
systems, energy and automation, including supervision of PhD
studies and execution of research programmes in these scientific
areas. Many research projects have been successfully conducted
while several Horizon 2020 are still in progress. In ENFLATE,
UoA is the leader of WP2 - Consumers incentivization for
energy services, while it participates in all other WPs.
</Modal.Body>
      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={nova} onClick={() => setnovaShow(true)}/>
<Modal 
        show={novaShow}
        onHide={() => setnovaShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>Nova<span> <a href="https://nova.gr/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setnovaShow(false)}/>
        </Modal.Header>
        <Modal.Body>NOVA is one of the largest alternative fixed operators that
provides broadband and pay TV services in Greece. Using
state‐of‐the‐art technologies and fully exploiting its
knowhow, NOVA offers a set of individual or bundled fixed
telephony, broadband internet and TV‐content services, to
customers, enterprises and Public Bodies throughout Greece.
The company utilizes and integrates technological solutions on
the basis of the latest telecommunications and transmission
infrastructure to develop and provide new services on its
network and beyond.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={sak} onClick={() => setSmsak(true)}/>
<Modal 
        show={smsak}
        onHide={() => setSmsak(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>Sak<span> <a href="https://www.sak.ch/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setSmsak(false)}/>
        </Modal.Header>
        <Modal.Body>SAK’s business areas include electricity generation, electricity
and heat supply, a modern fibre optic network and high-
performance internet, telephone, TV and mobile services, as well
as the promotion of e-mobility and renewable energy solutions
such as photovoltaic systems and heat pumps. With around 400
employees, we cover the entire value chain: From energy
procurement to planning, construction, operation and
maintenance of grids and facilities to sales and billing.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={rae} onClick={() => setLgrae(true)}/>
<Modal 
        show={lgrae}
        onHide={() => setLgrae(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>RAE<span> <a href="https://www.rae.gr/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setLgrae(false)}/>
        </Modal.Header>
        <Modal.Body>The Regulatory Authority for Energy (RAE) is an
independent administrative authority, which was established to
implement the provisions of Directive 96/92/EC for the
liberalization of the electricity market in Greece. RAE is a
member of the Council of European Energy Regulators.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={lachar} onClick={() => setlacharShow(true)}/>
<Modal 
        show={lacharShow}
        onHide={() => setlacharShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>Lachar<span> <a href="https://en.wikipedia.org/wiki/L%C3%A1char" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setlacharShow(false)}/>
        </Modal.Header>
        <Modal.Body>Láchar is a Spanish municipality in the province of Granada in
Andalusia. It is located in the western part of the Vega de
Granada, being one of the fifty-one entities that make up the
Metropolitan Area of Granada. It borders the municipalities of
Fuente Vaqueros, Cijuela and Pinos Puente. Lachar’s main
sectors of the economy are construction, industry, and
agriculture.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={stpg} onClick={() => setstpgShow(true)}/>
<Modal 
        show={stpgshow}
        onHide={() => setstpgShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>TPG<span> <a href="https://www.tpg.ch/en/lignes" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setstpgShow(false)}/>
        </Modal.Header>
        <Modal.Body>Transports Publics Genevois (TPG) is a predominantly
publicly funded transport operator that operates most of the
public transportation system in the Canton of Geneva
(Switzerland), but also in the Canton of Vaud and neighbouring
France. The company, headed by Denis Berdoz, operates trams,
trolleybuses and almost 500 buses on 65 different lines to
transport more than 600.000 people daily. The largest part of the
TPG fleet is now electric and the goal is to have the entire fleet
converted to this energy by 2030. TPG is also a forerunner in the
deployment of services involving autonomous
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={skiathos} onClick={() => setskiathosShow(true)}/>
<Modal 
        show={skiathosShow}
        onHide={() => setskiathosShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>Dimos Skiathou<span> <a href="https://www.n-skiathos.gr" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setskiathosShow(false)}/>
        </Modal.Header>
        <Modal.Body>The Municipality of Skiathos (MoS) includes the islets of
Tsougria, Tsougriaki, Maragos, Arkos, Troulonisi and Aspronisi
and is located in the northwest Aegean Sea, which is part of the
Sporades archipelago. The municipality covers an area of 49.898
km 2 and hosts nearly 5.000 inhabitants. Skiathos with its over 60
beaches scattered along its 44 km coastline as well as wooded
landscape is a popular tourist destination and attracts nearly
60.000 tourists in the summer months. In 2022 the Skiathos-Evia
electrical interconnection was performed by ADMIE attaining a
29 km underwater cable transmission and a 30 km transmission
cable transmitting power from the existing High Voltage
Substation in Mantoudi, Evia.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={eifitech} onClick={() => seteifitechShow(true)}/>
<Modal 
        show={eifitechShow}
        onHide={() => seteifitechShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>Eifi-Tech<span> <a href="https://eifi-tech.eu/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => seteifitechShow(false)}/>
        </Modal.Header>
        <Modal.Body>The European Institute for Innovation -Technology
(EIfI-Tech) office was established in 2015 in Schwäbisch
Gmünd, Baden Württemburg, Germany, to provide a vital
link between public and private members/stakeholders and
the EU institutions. The office helps EIfI-Tech to influence
policy, to provide access to funds to new technological and
service innovations, and to build European partnerships and
networks aligned with European Union&#39;s agenda. Through
its national, regional, and transnational work, EIfI-Tech
acts as a stimulator for economic activity, primarily
dedicated to technological advances and creation of new
employment and business development aligned with the
demands and desires of communities. As the name
suggests, EIfI-Tech is principally concerned with
technological developments; the main areas of activity are,
currently: energy, sustainability, transport, mobility, and
education. The EIfI-Tech operational team manages the
delivery of project work plans in collaborative partnerships
working in partnership with national/EU agencies, research
institutions, the private sector and people – the so called
“Quadruple Helix”. EIFI-Tech is dynamic across the
spectrum of energy sectors and is particularly concerned
with activities in applied research, feasibility, testing,
development, demonstration and deployment.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={ckw} onClick={() => setckwShow(true)}/>
<Modal 
        show={ckwshow}
        onHide={() => setckwShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>CKW<span> <a href="https://www.ckw.ch/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setckwShow(false)}/>
        </Modal.Header>
        <Modal.Body>CKW Group (CKW) is a leading Swiss provider of integrated
energy and building technology solutions. For over 125 years the
company has been supplying electricity to its now over 200,000
end customers in the cantons of Lucerne, Schwyz and Uri. It also
offers innovative products and services throughout Switzerland
in the fields of electricity, photovoltaics, heating technology, e-
mobility, building automation, ICT solutions and security. The
CKW Group employs over 2,100 people. With around 350
apprentices in 14 professions, it is the largest private-sector
training company in Central Switzerland. Further information
at www.ckw.ch
</Modal.Body>
      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={eso} onClick={() => setesoShow(true)}/>
<Modal 
        show={esoShow}
        onHide={() => setesoShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>ESO<span> <a href="https://www.eso.bg/?en" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setesoShow(false)}/>
        </Modal.Header>
        <Modal.Body>Elektroenergien Sistemen Operator (ESO) is the independent
transmission system operator of the Republic of Bulgaria,
engaged in managing and operation of the country’s high-voltage
electricity transmission grid. The transmission operator
guarantees responsibly and professionally the secure, reliable
and uninterrupted electricity supply in the country, and also
manages the transit of electricity flows through the territory of
Bulgaria and parallel operation with the electricity systems of the
countries in Continental Europe synchronous area. The company
manages the country’s electricity transmission system ensuring
its balanced and quality operation 24/7, 365 days a year.

ESO EAD is a sole-owner company whose single shareholder is
the Bulgarian Energy Holding EAD. It was incorporated by
Decision of Sofia City Court dated 4 January 2007. The
Company is holder of an electricity transmission license No. Л-
419-04/18.12.2013 issued by virtue of Decision No. Р-205 of 18
December 2013 by the State Energy and Water Regulatory
Commission (SEWRC). By Decision No. С-5/30.07.2015 and
Decision No. С-7/05.11.2015, EWRC certified ESO EAD as an
independent operator of the electricity transmission system of the
Republic of Bulgaria. The independent transmission operator
manages and maintains more that 15 thousand km of power lines
and 297 electrical substations. The electricity system operator
and its employee experts are governed by the striving to optimize
the functioning of the electricity transmission grid in the country
by implementing projects for development, modernization and
expansion with the construction of new facilities. The objective
of the transmission operator’s investment policy is to guarantee
the efficient and quality functioning of the Bulgarian electricity
system.
</Modal.Body>
      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={edg} onClick={() => setedgShow(true)}/>
<Modal 
        show={edgShow}
        onHide={() => setedgShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>EDG WEST<span> <a href="https://ermzapad.bg/bg/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setedgShow(false)}/>
        </Modal.Header>
        <Modal.Body>Electrodistribution Grid West AD (EDG West) is the distribution system operator in West Bulgaria. EDG West has over a century of experience in the field of electricity supply and distribution. It operates and manages the electricity distribution network, consisting of MV, LV and HV electricity power lines and electricity systems. The company distributes electricity in a territory with an area of 40 .00 square km, maintains and develops 58.000 km of network and serves over 2.100.000 consumers
</Modal.Body>
      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={geneve} onClick={() => setgeneveShow(true)}/>
<Modal 
        show={geneveShow}
        onHide={() => setgeneveShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>UNIGE<span> <a href="https://www.unige.ch/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setgeneveShow(false)}/>
        </Modal.Header>
        <Modal.Body>UNIVERSITE DE GENEVE (UNIGE) is a French-speaking
generalist university in natural and medical sciences as well as
the social and human sciences. The flagship sectors of research
at UNIGE are the life sciences (genetics, molecular biology,
bioinformatics), physics and chemistry, astrophysics, and also
specific fields in the social and human sciences, such as history,
life courses, the rights of the child or affective sciences. At the
national level, UNIGE is one of the Swiss leaders in hosting
national research centers (PRN). A flagship instrument of the
Swiss National Science Foundation, these centers encourage
scientific networks at an international level of excellence, from
basic research to application. Each pole consists of strengthening
a skills center located in a research institution and associating it
with a network of partner institutions where projects are also
carried out.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={blocks} onClick={() => setblocksShow(true)}/>
<Modal 
        show={blocksShow}
        onHide={() => setblocksShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>Blocks<span> <a href="https://blocks.care/en/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setblocksShow(false)}/>
        </Modal.Header>
        <Modal.Body>Blocks Health and Social Care (BLOCKS) is a newly
established company aiming to become the leading provider of
highest quality, world standard rehabilitation and long-term care
services in Bulgaria and Southeast Europe. The mission of
“Blocks” is to provide compassionate, patient centered, high
quality service to its patients and residents. Blocks is linked with
Blocks Medical City the largest ever project in rehabilitation
and long-term care in Bulgaria and Southeast Europe. It is an
integrated community offering a full range of post-acute care
services ranging from rehabilitation, through long-term care,
skilled nursing, memory care to end-of life care.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={noda} onClick={() => setnodaShow(true)}/>
<Modal 
        show={nodaShow}
        onHide={() => setnodaShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>NODA<span> <a href="https://noda.se/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setnodaShow(false)}/>
        </Modal.Header>
        <Modal.Body>NODAIS AB (NODA) designs digitalisation and automation
solutions for the European industry, closing the gaps that hinder
the IT/OT integration. It works on new technologies in open-
source platform for the design and run-time engineering of IoT
and System of Systems. Its R&amp;D area of interests cover
engineering processes, platforms integration, tools and tool

chains for the cost-efficient development of digitalisation,
connectivity, and automation system solutions in several fields
of application.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={eksta} onClick={() => setekstaShow(true)}/>
<Modal 
        show={ekstaShow}
        onHide={() => setekstaShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>EKSTA<span> <a href="https://www.eksta.se/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setekstaShow(false)}/>
        </Modal.Header>
        <Modal.Body><p>EKSTA is the housing company of the Municipality of.
Kungsbacka (SE). It is located on Sweden’s west coast and has
85,000 inhabitants. We own and manage just over 3,000
apartments and premises and have 50 employees. The name
EKSTA is created from our keywords Ekonomi (Economy),
Kvalitet (Quality), Service, Trivsel (Well-being) and Ansvar
(Responsibility). We are at the forefront with our work in solar
energy, biofuel, passive houses, and new technology. Ever since
the oil crises in the 1970´s, we have had a strategy to rely on
renewable energy sources as far as possible. For forty years, we
have had solar heating as a supplement in almost all new
production. We also worked closely with technical universities
and competent consulting companies. Together we have tested,
developed, and refined our energy concept and have become a
pioneer in our filed. Also, we have been sharing our experiences
and knowledge with several study visitors from all over the
world. Our vision is “EKSTA – the housing of the future”. We
meet the challenges of living in our houses now and in the
future. We build with commitment and responsibility. Our
employees are committed to our keywords. Thus, we are at the
forefront of solar energy, biofuel, and new technology, having
the most satisfied tenants in Western Sweden, which makes us
very proud.</p>
<p>
Annual Turnover: around EUR 39 million (390.320.508 SEK)
Profit after net financial items: approximately EUR 7,6 million
(76 .01. 320 SEK)΄Number of employees: 50</p>
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={hedno} onClick={() => sethednoShow(true)}/>
<Modal 
        show={hednoShow}
        onHide={() => sethednoShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>HEDNO<span> <a href="https://deddie.gr/en/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => sethednoShow(false)}/>
        </Modal.Header>
        <Modal.Body><p>HEDNO S.A. (Hellenic Electricity Distribution Network
Operator S.A.) was formed by the separation of the Distribution
Department from PPC S.A., according to L.4001/2011 and in
compliance with 2009/72/EC EU Directive relative to the
electricity market organization with the goal to undertake the
tasks of the Hellenic Electricity Distribution Network Operator.
It is a 100% subsidiary of PPC S.A.; however, it is independent
in operation and management retaining all the independence
requirements that are incorporated within the above-mentioned
legislative framework.</p>
<p>
HEDNO’s tasks include the operation, maintenance, and
development of the power distribution network in Greece, as
well as the assurance of a transparent and impartial access of
consumers and of all network users in general.
</p>
<p>
HEDNO manages networks which are strategic infrastructures of
crucial importance not only for the country’s economy and
Greek society, but also for the growth of the electricity market
within the scope of national and European standards, which have
been set to tackle climate change. HEDNO has an active role to
play in the transformation of the Greek energy market into a
market based on active consumers and new, eco-friendly
technologies while also successfully implementing all objectives
of the National Energy and Climate Plan for the next decade. In
this context, the Company has undertaken a series of actions
</p>
such as the implementation of upgrading and extension works
for the efficient introduction of RES in the system, the
introduction of energy store systems, the optimization of
operations for the interconnected system and Non-Interconnected
Islands and the digitalization of systems for the application of the
new market structure and advanced options for the consumers,
such as smart meters.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={setecho} onClick={() => setsetechoShow(true)}/>
<Modal 
        show={setechoShow}
        onHide={() => setsetechoShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>SETECHO<span> <a href="https://setechco.eu/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setsetechoShow(false)}/>
        </Modal.Header>
        <Modal.Body>Southeast European Technological Company Ltd is an
innovative technology and service provider company based in
Sofia, Bulgaria. Having international partnerships and alliances
with global players in the sector of energy and smart technology,
SETECHCO develops novelties and delivers smart energy
solutions. SETECHO is run by a passionate team of experts,
open to new challenges and productive collaborations.
SETECHCO’s working group is consisted of scientific experts in
engineering, smart energy technologies, data management,
economics, and marketing.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={turning} onClick={() => setturningShow(true)}/>
<Modal 
        show={turningShow}
        onHide={() => setturningShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>Turning Tables<span> <a href="https://www.turningtables.global/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setturningShow(false)}/>
        </Modal.Header>
        <Modal.Body>Turning Tables Living Lab is an open ecosystem with access to
thousands of end users, and thus, collects feedback on performed
tests in real-world environments. The team is open to the
scientific community and the industry with an Open Data
philosophy. Turning Tables Energy supports the energy
transition through social innovation in competitive and
productive scenarios for innovators who do not have access to
infrastructure and quality data. Its expert team offers guidance to
power producers, consumers, and retailers to augment their
business by developing procurement strategies national or
regional, EU energy markets. Turning Tables develop business
implementation plans, market and regulatory analyses for
sustainable business development, CBA analysis and modeling,
as well as economic and environmental risk assessments.
Turning Tables take low-carbon technology to markets and
secure funding in alignment with energy master plans, mobility
studies, renewable energy and city data visualizations. In
addition, Turning Tables design power generation, distribution,
and transmission project analyses for public and private
institutions interested in the installation, maintenance and
operation of renewable energy systems.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={abb} onClick={() => setabbShow(true)}/>
<Modal 
        show={abbShow}
        onHide={() => setabbShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>Hitachi ABB<span> <a href="https://global.abb/group/en" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setabbShow(false)}/>
        </Modal.Header>
        <Modal.Body><p>Our history dates back to the late 1800’s when electrical
engineering companies, ASEA and BBC (Brown Boveri &amp; Cie),
were formed during Europe’s industrial revolution. After
introducing electrical power transmission, rail and industrial
technologies, these two pioneering brands merged in 1988 to
create ABB. In the following three decades the company
continued to develop industry-leading power and automation
technologies through a combination of R&amp;D and tactical
acquisitions.</p>
<p>
In 2020, Hitachi and ABB&#39;s Power Grids&#39; business came together
in a joint venture to create a new global leader in pioneering and
digital technologies. As individual companies, both had been
innovating and improving technology for over a century.
Together, as Hitachi Energy, we bring forward and build on this
</p>
<p>
remarkable heritage to tackle today&#39;s challenges facing business
and society. Trusted as a partner of choice to the industry, we co-
create best-in-class solutions that deliver on the promise of a
sustainable energy future.
</p>
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={peiraias} onClick={() => setpeiraiasShow(true)}/>
<Modal 
        show={peiraiasShow}
        onHide={() => setpeiraiasShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>UPRC<span> <a href="https://www.unipi.gr/unipi/en/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setpeiraiasShow(false)}/>
        </Modal.Header>
        <Modal.Body>The University of Piraeus Research Centre (UPRC) was
founded in 1989 as part of the University that offers
administration and legal support to basic and applied research,
carried out by the staff of the University of Piraeus, in national
and international contexts. The high quality of research and
consulting work carried out to-date by the UPRC has led to a
large volume of collaborations either in international frameworks
(e.g., EU-funded projects) or undertaken on behalf of large
domestic companies, institutions and Ministries.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={cartif} onClick={() => setcartifShow(true)}/>
<Modal 
        show={cartifShow}
        onHide={() => setcartifShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>Cartif<span> <a href="https://www.cartif.es/en/home/ " target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setcartifShow(false)}/>
        </Modal.Header>
        <Modal.Body>CARTIF is a horizontal, private, and non-profit technological
center, whose main mission is providing innovative solutions to
the industry to enhance their processes, systems and products,
improving their competitiveness and creating new business
opportunities. In the ENFLATE project, CARTIF will lead the
identification and selection of flexibility services that will be
deployed in the different demo sites. Moreover, for the Spanish
pilot, they will lead the development of grid operation planning
algorithms, including demand flexibility, renewable energy
sources and storage management, with a view on the provision
of ancillary services and grid stability. Support will be provided
for non-technical losses detection, as well as for the assessment
of the estate health of elderly inhabitants depending on their
energy consumption habits.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={ipto} onClick={() => setiptoShow(true)}/>
<Modal 
        show={iptoShow}
        onHide={() => setiptoShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#1d0840'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>IPTO<span> <a href="https://www.admie.gr/en" target="_blank" rel="noreferrer">
          <FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#e04b26',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setiptoShow(false)}/>
        </Modal.Header>
        <Modal.Body>Independent Power Transmission Operator S.A. (IPTO) is
organized and operates as an Independent Transmission Operator
in line with the provisions of EU Directive 2009/72/EC. The
purpose of the company is the operation, control, maintenance
and development of the Hellenic Electricity Transmission
System, (HETS) to ensure the country’s supply with electricity
in an adequate, safe, efficient and reliable manner, as well as the
operation of the balancing market in Greece and the cross-border
capacity allocation and congestion management pursuant to the
principles of transparency, equality and free competition.
</Modal.Body>
      </Modal>
</div>
</div>

</div>
</strong>
</div>
</section>
              </Col>
            </Row>
            </Container>
            </section>
           
       
        <DarkFooter />
       
      </div>
    </>
  );
}

export default Partners;
