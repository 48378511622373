/*

=========================================================
* Now UI Kit React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2021 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/main/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import favicon from './components/Navbars/favicon.svg'
import { Provider } from "./context/Context";

// styles for this kit
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss";
import "./assets/demo/demo.css?v=1.5.0";
import "./assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit

import LandingPage from "./pages/LandingPage.js";
import ProjectPage from "./pages/Project.js"; 
import BlogList from "./components/Blog/BlogList";
import SingleBlog from "./components/Blog/BlogDetails";
import Partners from "./pages/Partners.js";
import Outcomes from "./pages/Outcomes";
import News from "./pages/News";
import Videos from "./pages/Videos";
import SinglePage from "./pages/SinglePage";
import { hotjar } from 'react-hotjar';

hotjar.initialize('3468658', '6');

// Identify the user
hotjar.identify('USER_ID', { userProperty: 'value' });

// Add an event
hotjar.event('button-click');

// Update SPA state
hotjar.stateChange('https://enflate.eu/');

// Check if Hotjar has been initialized before calling its methods
if (hotjar.initialized()) {
  hotjar.identify('USER_ID', { userProperty: 'value' });
}


ReactDOM.render(

  <BrowserRouter>
  <Helmet>
        <meta charSet="utf-8"/>
        <title>enflate</title>
        <link rel="image_src" href="/static/media/tubers_logo2.svg" />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        <meta name="description" content="App Description" />
        <meta name="theme-color" content="#008f68" />

  </Helmet>
    <Switch>
      <Switch>
        <Provider>
        <Route exact path="/" render={(props) => <LandingPage {...props} />} />
        <Route path="/project" component={ProjectPage} />
        <Route path="/partners" component={Partners} />
        <Route path="/outcomes" component={Outcomes} />
        <Route path="/news" component={News} />
        <Route path="/bloglist" component={BlogList} />
        <Route path="/blog/:id" component={SingleBlog} />
        <Route path="/videos" component={Videos} />
        </Provider>
      </Switch>
    </Switch>
  </BrowserRouter>
  ,
  document.getElementById("root")
);
// <Route exact path="/" render={(props) => <LandingPage {...props} />} />
// <Route path="/project" component={ProjectPage} />
// <Route path="/partners" component={Partners} />
// <Route path="/outcomes" component={Outcomes} />
// <Route path="/news" component={News} />
// <Route path="/bloglist" component={BlogList} />
// <Route path="/blog/:id" component={SingleBlog} />