import React, {useContext} from 'react'
import "../assets/scss/styles.scss"

import CookieConsent, { Cookies } from "react-cookie-consent";
import ContactForm from '../components/contact'
import DarkFooter from "../components/Footers/DarkFooter.js";
import cons from './map.png'
import MapChart from '../components/MapChart';
import Mailchimp from '../components/Mailchimp'




// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../components/Headers/LandingPageHeader.js";
import { Context } from '../context/Context'
import video from '../assets/img/video.mp4'




function LandingPage() {
  const {about, isAboutLoading} = useContext(Context)
  const {titlesection1, titlesection2, section1Content, section2Content, section2Content2, subscribe} = about
  console.log(about)
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        
        <LandingPageHeader />
       
        <section className="section section-about-us" id='mission'>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{color:'#3a1772 '}}>{titlesection1}</h2>
                <p className="description" style={{textAlign:'justify',color:'#3a1772',fontSize:'1em'}} dangerouslySetInnerHTML={{__html: section1Content }}>
                </p>
                <div>
</div>
   
              </Col>
            </Row>
            </Container>
            </section>
            <section className="section section-about-us" id='consortium' style={{}}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8" >
                <h2 className="title" style={{color:'#ea9b84'}}>{titlesection2}</h2>
                <p className="description" style={{textAlign:'justify',color:'white'}} dangerouslySetInnerHTML={{__html: section2Content }}>
              
                </p>
              
                <MapChart/>
                <br/> <br/>
           
              
                <p className="description"  style={{textAlign:'justify',color:'white'}} dangerouslySetInnerHTML={{__html: section2Content2 }}>
               </p>

              </Col>
              
            </Row>
            </Container>
            </section>
         
        <section id='latestnews' style={{color:'#e1e1e1', borderTop:'3px solid #ea9b84'}}>
          
        <div className="section section-team text-center" style={{backgroundColor:'#1d0840'}}>
        <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
              
              <p className="description" style={{textAlign:'justify',color:'white'}} dangerouslySetInnerHTML={{__html: subscribe }}/>
              </Col>
            </Row>
            </Container>
        </div>
        </section>
       
        <ContactForm/>
        <DarkFooter />
        <CookieConsent
  location="bottom"
  buttonText="Agree"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#f4f4f4",padding:'10px',position:'fixed', overflowY:'auto',borderTop:'2px solid #e04b26',display:'block'}}
  buttonStyle={{ color: "#dadada", fontSize: "13px",borderRadius:'5px',background:'#3a1773'}}
  expires={150}
>
  <h6 style={{color:'#3a1773',textTransform:'none'}}>In order to provide you with the best online experience this website uses cookies.</h6>
  
     <h6 style={{color:'#3a1773',textTransform:'none'}}>By using our website, you agree to our use of cookies.</h6>
    
</CookieConsent>
      </div>
    </>
  );
}

export default LandingPage;
