import React from 'react'
import { Slide } from 'react-slideshow-image';
import Fade from 'react-reveal/Fade';


import Slideshow from './slideshow.js'

function Head() {
  return (
    <header id='head'>
      
    <div class="containernew">
    
    <section class="showcase" style={{backgroundColor:'#1b1d2800'}}>
      <div style={{marginTop:'150px',padding:'5%'}}>
        <Fade duration={2000}>
        <h2 id='titleh2' style={{fontSize:'2.4em'}}>Flexibility <br/> <span style={{fontSize:'0.8em',color:'#ec4e20'}}>From grid to market across sectors</span></h2>
        </Fade>
        <Fade duration={3000}>
        <h4 >
        <span>EN</span>abling <span>FL</span>exibility provision by all Actors and sectors through markets and digital TEchnologies
        </h4>
        </Fade>
        <Fade duration={5000}>
        
       
        </Fade>
        <div class="home-main__split home-main__split--left">
        <span class="home-main__small-text">scroll down</span>
      </div>
        </div>
        
    </section>
    
</div>

<div class="video-container">
    <Slideshow/>
</div>
</header>


)}
export default Head;