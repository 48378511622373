import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import { client } from '../../context/client';
import ExamplesNavbar from '../Navbars/ExamplesNavbar';
import DarkFooter from '../Footers/DarkFooter';
import { marked } from 'marked'

const SingleBlog = () => {
  const [singleBlogPost, setSingleBlogPost] = useState([])
  const [description, setDescription] = useState({})

  let { id } = useParams();
  console.log(id)


  useEffect(() => {
    const getEntryById = async () => {
      try {
        await client.getEntry(id).then((entries) => {
          setSingleBlogPost(entries)
          setDescription(marked(entries.fields.postdescription))
        })
      } catch (error) {
        console.log(error);
      }
    };
    getEntryById()
    
  }, [id])


  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

              <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#3a1772'}} className="post-title pt-3">{singleBlogPost?.fields?.blogTitle}</h3>
                  <p className="post-meta" style={{color:'#ea9b84'}}>
                  
                    {singleBlogPost?.fields?.createdDate === undefined ?
                      "loading"
                      :
                      new Intl.DateTimeFormat('en-GB', {
                        month: 'long',
                        day: '2-digit',
                        year: 'numeric',
                      }).format(new Date(singleBlogPost?.fields?.createdDate))

                    }
                
                  </p>
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0  20px 0px 0px'}}src={singleBlogPost?.fields?.blogImage?.fields?.file?.url} title="" alt={singleBlogPost?.fields?.blogTitle} width="578" height="291" />
                <div style={{textAlign:'justify'}}dangerouslySetInnerHTML={{ __html: description }}/>
               
                
                  <img id='blogimg' style={{float:'left',margin:' 0  20px 0px 0px'}}src={singleBlogPost?.fields?.image2?.fields?.file?.url} width="250"  />
                  <img id='blogimg' style={{float:'left',margin:' 0  20px 0px 0px'}}src={singleBlogPost?.fields?.image3?.fields?.file?.url} width="250"  />
                  <img id='blogimg' style={{float:'left',margin:' 0  20px 0px 0px'}}src={singleBlogPost?.fields?.image4?.fields?.file?.url} width="250"  />



                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default SingleBlog