/*eslint-disable*/
import React,{useContext} from "react";
// reactstrap components
import { Container } from "reactstrap";
import eu from '../../assets/img/logos/eu.jpg'
import {Context} from '../../context/Context'

function DarkFooter() {
  const {about, isAboutLoading} = useContext(Context)
  const {titlesection1, titlesection2, section1Content, section2Content, section2Content2, facts} = about
  return (
    <footer className="footer">
    
    <div class="container py-5">

      <div class="row py-4">
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">

        <h6 class="text-uppercase font-weight-bold mb-4">ENFLATE Facts</h6>
        <hr/>
   
        <ul id='ulu' style={{color:'#dadada'}}>
        <li style={{display:'block'}} dangerouslySetInnerHTML={{__html: facts }}></li>  
          </ul>

        </div>
        
        <div class="col-lg-2 col-md-6 mb-4 mb-lg-0" id='marginfooter'>
          <h6 class="text-uppercase font-weight-bold mb-4" style={{fontSize:'30px'}}>Follow us</h6>
          <hr style={{width:'140px'}}/>
          <ul class="list-unstyled mb-0">
            <li class="mb-2"> <a target="_blank"  href='https://www.linkedin.com/showcase/enflate'> <i id='social' className="fab fa-linkedin"></i></a></li>
            <li class="mb-2"> <a target="_blank"  href='https://www.youtube.com/channel/UCvUtZxalqW_LK5NpmQS2AeQ'> <i id='social' className="fab fa-youtube"></i></a></li>
            <li class="mb-2"> <a target="_blank"  href='https://twitter.com/enflateproject'> <i id='social'className="fab fa-twitter"></i></a></li>
          </ul>
          
        </div>
        <div class="col-lg-4 col-md-6 mb-lg-0" id='marginfooter'>
          <img src={eu} style={{width:'50px'}}></img>
          <hr/>
          <p style={{color:'#dadada', fontSize:'1em'}}>This project has received funding from the European Union under the Horizon Europe programme.</p>
          <p style={{color:'#dadada', fontSize:'1em'}}><a id='cord' href='https://bridge-smart-grid-storage-systems-digital-projects.ec.europa.eu/'>BRIDGE</a> is a European Commission initiative which unites Horizon 2020 and Horizon Europe Smart Grid, Energy Storage, Islands, and Digitalisation Projects fostering  knowledge sharing amongst projects through four 
            different Working Groups representing the main areas of interest:  Data management, Business models, Regulations, Consumer and Citizen engagement</p>

          <hr/>
          
        </div>
      </div>
    </div>
    <div class="bg-light py-4">
      <div class="container text-center">
        <p class=" mb-0 py-2" style={{color:'#3a1772'}}>© 2022 ENFLATE. All rights reserved.</p>
      </div>
    </div>

    </footer>
  );
}

export default DarkFooter;


/*<a id='a'href="https://www.w3schools.com" style={{color:'#dadada',fontSize:'14px',cursor:'pointer'}}>Privacy policy</a>*/