import React, {useState, useEffect, useCallback, useContext} from 'react'
import { client } from './client'
import { CleanUpAbout, CleanUpPartners } from './helpers'
import { CleanUpProject } from './helpers'


export const Context = React.createContext()

export const Provider = (props) =>{
    const[about, setAbout] = useState({})
    const[isAboutLoading,setIsAboutLoading] = useState(false)
    const[project, setProject] = useState({})
    const[isProjectLoading,setIsProjectLoading] = useState(false)
    const[partners, setPartners] = useState({})
    const[isPartnersLoading,setIsPartnersLoading] = useState(false)

    const saveAboutData = useCallback((rawdata) =>{
        const cleanAboutData = CleanUpAbout(rawdata)
        setAbout(cleanAboutData)
    }, [])

    const saveProjectData = useCallback((rawdata) =>{
        const cleanProjectData = CleanUpProject(rawdata)
        setProject(cleanProjectData)
    }, [])

    const savePartnersData = useCallback((rawdata) =>{
        const cleanPartnersData = CleanUpPartners(rawdata)
        setPartners(cleanPartnersData)
    }, [])

const getAbout = useCallback(async () => {
setIsAboutLoading(true)
try {
    const response = await client.getEntry('3ciBAzlXodt5FeIMXpHO4k')
    if (response){
        saveAboutData(response)
    }else{
        setAbout({})
    }
    setIsAboutLoading(false)
} catch (error) {
    console.log(error)
    setIsAboutLoading(false)

}
}, [saveAboutData])

useEffect(()=> {
    getAbout()
}, [getAbout])

const getProject = useCallback(async () => {
    setIsProjectLoading(true)
    try {
        const response = await client.getEntry('3lUdFB7zuVHaOtZB3MZcMx')
        if (response){
            saveProjectData(response)
        }else{
            setProject({})
        }
        setIsProjectLoading(false)
    } catch (error) {
        console.log(error)
        setIsProjectLoading(false)
    
    }
    }, [saveProjectData])
    
    useEffect(()=> {
        getProject()
    }, [getProject])

    const getPartners = useCallback(async () => {
        setIsPartnersLoading(true)
        try {
            const response = await client.getEntry('52VBnNtlbf0Ngm7vPCNqSs')
            if (response){
                savePartnersData(response)
            }else{
                setPartners({})
            }
            setIsPartnersLoading(false)
        } catch (error) {
            console.log(error)
            setIsPartnersLoading(false)
        
        }
        }, [savePartnersData])
        
        useEffect(()=> {
            getPartners()
        }, [getPartners])

    const contextData = {
        about,
        isAboutLoading,
        project,
        isProjectLoading,
        partners,
        isPartnersLoading
    }
    return(
    <Context.Provider value={contextData}>
        {props.children}
    </Context.Provider>
    )
}