import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { client } from '../../context/client'
import '../../assets/scss/newstyle.scss'

const BlogList = () => {
  const [blogPosts, setBlogPosts] = useState([])


  useEffect(() => {
    const getAllEntries = async () => {
      try {
        await client.getEntries({content_type:'news'}).then((entries) => {
          console.log(entries)
          setBlogPosts(entries)
        })
      } catch (error) {
        console.log(`Error fetching authors ${error}`);
      }
    };
    getAllEntries()
  }, [])



  return (
    <div id="layout" className="pure-g">
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts" sty>

            {blogPosts?.items?.map((post) => (
              <section  key={post.sys.id}>
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img" src={post.fields.blogImage.fields.file.url} title="" alt={post.fields.blogTitle} />
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a href="#">{post.fields.blogTitle}</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 {new Intl.DateTimeFormat('en-GB', {
                        month: 'long',
                        day: '2-digit',
                        year: 'numeric',
                      }).format(new Date(post.fields.createdDate))}
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">{post.fields.blogSummary}</div>
				<ul class="postcard__tagbox">
					
          <Link
                    to={`/blog/${post.sys.id}`}
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>
            ))}
          </div>


          
        </div>
      </div>
    </div>
  )
}

export default BlogList