import React, { useEffect, useState } from 'react'
import "../assets/scss/styles.scss"
import Img from 'react-cloudinary-lazy-image'

import DarkFooter from "../components/Footers/DarkFooter.js";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BlogList from "../components/Blog/BlogList";
import BlogDetails from "../components/Blog/BlogDetails";
import MyPDF from './pressrelease.pdf';
import Press2 from './pressrelease2.pdf';
import MyPDF2 from './newsletter_no1.pdf';
import MyPDF3 from './newsletter_no2.pdf';
import MyPDF4 from './newsletter_no3.pdf';
import icon from './pdficon2.png'
import { client } from '../context/client'

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../components/Headers/LandingPageHeader.js";



function News() {

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <section className="section section-about-us" id='publications'>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{color:'#3a1772',marginTop:'50px'}}>News</h2>
                
              </Col>
              <BlogList/>
            </Row>
            </Container>
            <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{color:'#3a1772',marginTop:'50px'}}>Press Releases</h2>
                
              </Col>
              <ul style={{listStyle:'auto'}}>
              <li style={{textAlign:'left'}}>
              <a id='cv' href={MyPDF} download="ENFLATE NO 1 Press Release - Launch Event, Sept 14-15 2022.pdf"> ENFLATE NO 1 Press Release - Launch Event, Sept 14-15 2022 <img style={{width:'22px'}} src={icon}></img> </a>
              </li>
              <li style={{textAlign:'left'}}>
              <a id='cv' href={Press2} download=" ENFLATE NO 2 Press Release - SCM Granada March, March 22-23 2023.pdf">  ENFLATE NO 2 Press Release - SCM Granada March, March 22-23 2023 <img style={{width:'22px'}} src={icon}></img> </a>
              </li>
              <li style={{textAlign:'left'}}>
          HSLU: <a href='https://www.hslu.ch/de-ch/hochschule-luzern/ueber-uns/medien/medienmitteilungen/2022/11/17/news-site/'>https://www.hslu.ch/de-ch/hochschule-luzern/ueber-uns/medien/medienmitteilungen/2022/11/17/news-site/</a>
          </li>
          <li style={{textAlign:'left'}}>
          EPEX: <a href='https://www.epexspot.com/en/news/european-horizon-project-enflate-digitalisation-energy-transition'>https://www.epexspot.com/en/news/european-horizon-project-enflate-digitalisation-energy-transition </a>
          </li>
          <li style={{textAlign:'left'}}>
          SAK: <a href='https://www.sak.ch/de/presse/europaeisches-horizon-projekt-enflate-digitalisierung-fuer-die-energiewende'>https://www.sak.ch/de/presse/europaeisches-horizon-projekt-enflate-digitalisierung-fuer-die-energiewende</a>
          </li>
          </ul>
            </Row>
            </Container>
            <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{color:'#3a1772',marginTop:'50px'}}>Newsletter</h2>
              </Col>
               <ul style={{listStyle:'auto'}}>
              <li style={{textAlign:'left',minWidth:'500PX',paddingBottom:'10px'}}>
              <a id='newsletter' href={MyPDF2} download="Newsletter No1.pdf">Newsletter No1 <img style={{width:'22px'}} src={icon}></img></a>
              </li> 
              <li style={{textAlign:'left',minWidth:'500PX',paddingBottom:'10px'}}>
              <a id='newsletter' href={MyPDF3} download="Newsletter No2.pdf">Newsletter No2 <img style={{width:'22px'}} src={icon}></img></a>
              </li> 
              <li style={{textAlign:'left',minWidth:'500PX'}}>
              <a id='newsletter' href={MyPDF4} download="Newsletter No3.pdf">Newsletter No3 <img style={{width:'22px'}} src={icon}></img></a>
              </li> 
          </ul>
            </Row>
           
            </Container>
            </section>   
        <DarkFooter />
        
      </div>
    </>
  );
}

export default News;

/*
    <section id='deliverables'>
        <div className="section section-team text-center" style={{backgroundColor:'#ea9b84'}}>
        <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title" style={{color:'#ededed'}}>Newsletter</h2>
             
         
              </Col>
            </Row>
            </Container>
        </div>
        </section>
*/