import React,{Component} from 'react'

import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import test from '../assets/img/test2.svg'

import {
    Button,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
  } from "reactstrap";

  export default class ContactForm extends Component{
    constructor(props){
      super(props);
      this.state={ name:"", email:"", message:"",firstFocus:'',lastFocus:'',checked:''}
      this.handleSubmit = this.handleSubmit.bind(this)
    
    }
    //const [firstFocus, setFirstFocus] = React.useState(false);
    //const [lastFocus, setLastFocus] = React.useState(false);
    //const [checked, setChecked] = React.useState(false);

    change = e => {
      this.setState({
        [e.target.name]: e.target.value
      });
    };
    handleSubmit(e) {
      console.log('sent')
      e.preventDefault();
      emailjs.sendForm('service_1z96cia', 'template_33dznlx', e.target, 'ocfVC3AvRIPyxgkZm')
      .then((result) => {
          this.setState({ name:"", email:"", message:""})
          console.log(result.text);
          Swal.fire({
            icon: 'success',
            title: "Message Sent Successfully"
          })
          
      }, (error) => {
        Swal.fire({
          icon: 'error',
          title: "Ooops, something went wrong",
          text: error.text,
        })
          console.log(error.text);
      });
    }
    render(){
  return (
    <section id='contact' className="section section-contact-us text-center" style={{}}>
      <form id="contactform" method="post" onSubmit={this.handleSubmit}>
          <Container>
         
            <h2 className="title" style={{color:'#1d0840'}}>Contact Us</h2>
            <Row>
              <Col className="text-center ml-auto mr-auto" id='contact'lg="6" md="8">
                <InputGroup
                  className={
                    "input-lg" + (this.state.firstFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder='Name...'
                    required
                    value={this.state.name}
                    onChange={e => this.change(e)}

                   onFocus={() => this.setState({ firstFocus:true })}
                   onBlur={() => this.setState({ firstFocus:false })}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    "input-lg" + (this.state.lastFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    required
                    value={this.state.email}
                    onChange={e => this.change(e)}
                    placeholder="Email..."
                    onFocus={() => this.setState({ lastFocus:true })}
                    onBlur={() => this.setState({ lastFocus:false })}
                  ></Input>
                </InputGroup>
                <div className="textarea-container">
                  <Input
                    cols="80"
                    required
                    name="message"
                    value={this.state.message}
                    placeholder="Type a message..."
                    onChange={e => this.change(e)}
                    rows="4"
                    type="textarea"
                  ></Input>
                </div>
                <div className="send-button">
                  <Button
                    type='submit'
                    className="btn-round"
                    color="info"
                    id='btnc'
                    size="lg"
                  >
                    Send Message
                  </Button>
                  
                </div>
              </Col>
            </Row>
          </Container>
          </form>
        </section>


);
 }}
