import React from "react";
import "../assets/scss/styles.scss"
import Img from 'react-cloudinary-lazy-image'



import DarkFooter from "../components/Footers/DarkFooter.js";
import MyPDF3 from './BROCHURE.pdf';
import MyPDF5 from './BROCHURE2.pdf';
import MyPDF4 from './enflate_poster.pdf';
import Banner1 from './ENFLATE_BANNER_VERSION1.pdf';
import Banner2 from './ENFLATE_BANNER_VERSION2.pdf';
import icon from './pdficon2.png'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react'
import { client } from '../context/client'



// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../components/Headers/LandingPageHeader.js";



function Outcomes() {
  const [Projectvideos, setProjectvideos] = useState([])
  const [Interviewvideos, setInterviewvideos] = useState([])


  useEffect(() => {
    const getAllEntries = async () => {
      try {
        await client.getEntries({content_type:'projectVideos'}).then((entries) => {
          console.log(entries.items,'hhhhh')
          setProjectvideos(entries)
        })
      } catch (error) {
        console.log(`Error fetching authors ${error}`);
      }
    };
    const getAllInterviews = async () => {
      try {
        await client.getEntries({content_type:'interviewVideos'}).then((entries) => {
          console.log(entries.items,'hhhhh')
          setInterviewvideos(entries)
        })
      } catch (error) {
        console.log(`Error fetching authors ${error}`);
      }
    };
    getAllInterviews()
    getAllEntries()
  }, [])
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <section className="section section-about-us" id='project'>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{color:'#3a1772',marginTop:'50px'}}>Project Videos</h2>
              
              </Col>
              {Projectvideos?.items?.map((projectvideo) => (<div>
              <iframe  key={projectvideo.sys.id} style={{padding:'5px'}} width="360" height="215" src={projectvideo.fields.link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
           </div> ))}
              </Row>
            </Container>
            </section>
            <section id='interview'>
        <div className="section section-team text-center" style={{backgroundColor:'#3a1772'}}>
        <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title" style={{color:'#ededed'}}>Interview Videos</h2>
              </Col>
              {Interviewvideos?.items?.map((interviewvideo) => (<div>
              <iframe style={{padding:'5px'}} width="360" height="215" src={interviewvideo.fields.link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              </div> ))}
              </Row>
            </Container>
        </div>
        </section>
      
        <DarkFooter />
        
      </div>
    </>
  );
}

export default Outcomes;
